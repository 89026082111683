<template>
	<el-image-viewer :z-index="40100" :append-to-body="true" v-if="images && images.length > 0" :on-close="closeViewer" :url-list="images"/>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
	components:{ElImageViewer},
	props:{
		images:{
			type:Array,
			default:[]
		}
	},
	methods:{
		closeViewer(){
			this.$emit('closeViewer')
		}
	}
}
</script>
