import Vue from 'vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

ElementUI.Dialog.props.closeOnClickModal = false;
ElementUI.Dialog.props.closeOnPressEscape = false;
ElementUI.Dialog.props.destroyOnClose = true;

Vue.use(ElementUI,{
	zIndex:40000,
	size:"small"
});
