<template>
	<span class="wrap">
		<el-input class="in" type="number" v-model="hour">
			<template slot="append">小时</template>
		</el-input>
		<el-input class="in" type="number" v-model="min">
			<template slot="append">分钟</template>
		</el-input>
		<el-input class="in" type="number" v-model="sec">
			<template slot="append">秒</template>
		</el-input>
	</span>
</template>

<script >
export default {
	props:{
		value:"",
	},
	computed:{
		hour:{
			get(){
				return this.value > 0 ? Math.floor(this.value /3600) : 0
			},
			set(value){
				this.setValue(value,this.min,this.sec);
			}
		},
		min:{
			get(){
				return this.value > 0 ? Math.floor((this.value - this.hour*3600)/60) : 0
			},
			set(value){
				this.setValue(this.hour,value,this.sec);
			}
		},
		sec:{
			get(){
				return this.value % 60 || 0
			},
			set(value){
				this.setValue(this.hour,this.min,value);
			}
		}
	},
	methods:{
		setValue(hour,min,sec){
			const value = Number(hour)*3600+Number(min)*60+Number(sec);
			this.$emit('input',value)
		}
	}
}
</script>

<style lang="scss" scoped>
.wrap{display: flex;
	.in{margin-right: 10px;}
	.in:nth-last-child(1){margin-right: 0;}
}
</style>
