<script >
import QRCode from "qrcodejs2"
import {isIE} from "@/common/type.js";

export default {
	props:{
		qrcode_info:{
			type:Object,
			default:{
				url:"",
				title:"二维码"
			}
		},
	},
	mounted() {
		this.createQrcode()
	},
	methods:{
		createQrcode(){
			const qrcode = new QRCode('qrcode', {
				text:this.qrcode_info.url,
				width: 300,
				height: 300,
				colorDark: '#000000',
				colorLight: '#ffffff',
				correctLevel: QRCode.CorrectLevel.H,
			});
		},
		download(){
			const src = document.querySelector('#qrcode img').src;
			if(isIE()){
				const bstr = atob(src.split(',')[1])
				let n = bstr.length
				const u8arr = new Uint8Array(n)
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n)
				}
				const blob = new Blob([u8arr])
				// 调用浏览器的方法，调起IE的下载流程
				window.navigator.msSaveOrOpenBlob(blob, this.qrcode_info.title + '.' + 'png')
			}else{
				const element = document.createElement('a');
				element.href = src;
				element.title = this.qrcode_info.title;
				element.download = this.qrcode_info.title;
				element.click();
			}
		},
		close(){
			this.$emit('close')
		}
	}
}

</script>
<template>
	<el-dialog :title="qrcode_info.title" :visible="true" @close="close">
		<div class="qrcode-box">
			<div id="qrcode" ref="qrcode"></div>
		</div>
		<div slot="footer" class="dialog-footer">
			<el-button type="" @click="download">下载</el-button>
			<el-button type="primary" @click="close">确定</el-button>
		</div>
	</el-dialog>
</template>

<style lang="scss" scoped>
.qrcode-box{text-align: center;display: flex;justify-content: center;}
</style>
